import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 834.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,834.000000) scale(0.100000,-0.100000)">
					<path d="M4815 6280 c-59 -15 -113 -37 -177 -73 -93 -52 -94 -54 -68 -129 13
-38 25 -101 29 -158 l6 -95 90 -32 c50 -18 108 -40 130 -48 22 -7 85 -30 140
-51 213 -77 351 -126 373 -131 12 -3 22 -9 22 -14 0 -5 5 -9 11 -9 14 0 84
-55 124 -96 82 -87 110 -141 125 -241 5 -39 10 -353 10 -741 0 -422 4 -672 10
-670 10 3 75 66 565 542 375 365 541 529 572 568 61 75 109 190 126 301 8 50
-20 164 -51 209 -59 85 -86 100 -462 261 -114 49 -292 126 -435 189 -49 22
-116 51 -147 64 -31 13 -86 37 -123 54 -36 16 -67 30 -70 30 -2 0 -32 13 -67
29 -35 16 -88 39 -118 51 -30 12 -77 32 -105 44 -27 13 -120 53 -206 89 -136
59 -162 67 -215 66 -32 -1 -72 -4 -89 -9z"/>
					<path d="M4084 5633 c-27 -32 -67 -78 -89 -103 -22 -25 -51 -59 -65 -75 -39
-46 -332 -386 -361 -420 -168 -191 -310 -364 -338 -410 -68 -112 -96 -249 -71
-339 12 -43 65 -112 352 -461 27 -33 104 -127 171 -210 66 -82 124 -152 127
-155 4 -3 28 -32 55 -65 163 -203 347 -423 364 -437 71 -60 194 -95 296 -83
66 7 135 24 150 37 5 4 15 8 22 8 15 0 107 55 142 85 68 58 244 236 238 241
-5 6 -122 31 -242 53 -302 56 -368 74 -484 129 -84 40 -197 142 -229 207 -52
104 -56 142 -44 390 14 274 19 384 32 695 5 129 16 390 25 580 18 409 17 390
6 390 -5 0 -31 -26 -57 -57z"/>
					<path d="M4564 4408 c-16 -25 154 -134 274 -175 125 -43 387 -45 387 -3 0 12
-22 14 -120 11 -179 -4 -327 41 -462 141 -56 41 -68 45 -79 26z"/>
					<path d="M4467 4224 c-14 -14 15 -43 95 -96 182 -122 288 -157 531 -175 48 -3
95 -1 125 7 26 7 55 14 65 17 9 2 17 13 17 24 0 22 -19 24 -118 9 -121 -19
-340 24 -477 93 -22 11 -75 44 -118 73 -73 50 -105 63 -120 48z"/>
					<path d="M4345 4033 c-12 -31 122 -131 285 -213 166 -84 261 -104 495 -104
194 -1 240 8 240 49 0 23 -15 24 -172 10 -247 -21 -560 82 -766 252 -34 27
-72 30 -82 6z"/>
					<path d="M2522 2378 c3 -78 4 -83 25 -83 12 0 24 -3 27 -6 4 -3 3 -101 0 -218
l-7 -211 -29 0 -28 0 0 -85 0 -85 190 0 190 0 0 84 0 85 -32 3 -33 3 0 60 c0
56 2 60 24 63 36 5 51 -22 51 -92 0 -79 34 -154 82 -184 46 -29 122 -44 176
-36 53 8 116 64 127 113 8 38 18 39 41 4 24 -39 61 -70 114 -96 38 -19 61 -22
150 -22 98 0 110 2 175 33 38 18 73 38 77 45 12 18 26 -9 20 -39 l-4 -24 183
2 184 3 0 80 0 80 -37 3 -38 3 0 82 c0 45 3 92 6 105 8 30 50 40 72 18 15 -14
17 -42 17 -196 l0 -180 153 0 152 0 1 78 c0 64 2 74 12 57 7 -11 35 -43 63
-71 69 -70 125 -88 249 -82 78 4 101 10 156 37 35 18 72 41 82 50 18 18 17 20
-32 72 -28 30 -51 57 -51 62 0 4 18 7 39 7 47 0 51 8 51 106 0 94 20 102 45
18 9 -31 35 -117 59 -192 l43 -137 125 -3 125 -3 22 78 c13 43 25 86 28 97 8
28 18 9 47 -86 l27 -88 128 0 128 0 12 43 c7 23 33 111 58 195 42 140 48 152
69 152 23 0 24 2 24 85 l0 85 -145 0 -145 0 0 -80 c0 -61 3 -81 16 -88 12 -7
14 -17 9 -45 -10 -54 -21 -53 -33 1 -5 26 -20 83 -33 127 l-24 80 -112 3 -112
3 -12 -43 c-6 -24 -17 -63 -25 -88 -7 -25 -16 -62 -20 -82 -7 -43 -19 -42 -29
4 -5 21 -3 33 6 39 11 5 13 28 11 88 l-4 82 -156 -3 -157 -3 -2 -35 c-1 -19
-2 -42 -2 -50 -1 -9 -14 -1 -38 22 -48 46 -84 64 -155 77 -199 36 -362 -92
-361 -285 0 -39 4 -81 8 -92 5 -18 2 -22 -18 -22 -24 0 -24 2 -30 121 -8 148
-20 188 -65 234 -64 63 -175 72 -253 20 -33 -21 -43 -24 -50 -13 -5 7 -9 16
-9 21 0 4 -56 7 -125 7 -93 0 -125 -3 -126 -12 -1 -7 -2 -44 -3 -83 -1 -65 1
-70 22 -73 21 -3 22 -7 22 -112 0 -105 -1 -109 -22 -112 -16 -2 -23 -10 -23
-25 0 -11 -3 -24 -7 -28 -7 -7 -87 73 -88 88 0 4 16 7 35 7 46 0 57 23 54 113
-4 138 -77 219 -221 246 -138 25 -275 -39 -337 -157 -7 -13 -18 -56 -25 -95
l-12 -72 -37 -3 c-35 -3 -37 -5 -37 -40 0 -28 -3 -33 -10 -22 -5 8 -10 33 -10
55 0 51 -30 100 -77 127 l-37 22 42 18 c82 36 114 130 78 231 -18 50 -41 74
-101 106 -50 26 -52 26 -303 29 l-253 4 3 -84z m411 -109 c23 -28 22 -81 -2
-103 -11 -10 -38 -20 -60 -23 l-41 -6 0 75 c0 41 3 78 8 82 13 14 77 -3 95
-25z m707 -200 c7 -11 10 -22 8 -24 -9 -8 -120 -10 -124 -3 -3 5 2 18 12 29
25 27 89 26 104 -2z m1255 1 c10 -11 15 -23 12 -26 -8 -8 -116 -9 -123 -1 -3
3 1 14 8 26 17 28 78 29 103 1z m-1155 -185 c-20 -24 -112 -49 -153 -41 -36 7
-67 29 -67 47 0 5 51 9 116 9 102 0 115 -2 104 -15z m1260 1 c-19 -24 -108
-49 -147 -43 -34 6 -73 31 -73 49 0 4 52 8 116 8 100 0 115 -2 104 -14z"/>
					<path d="M5850 1612 c0 -37 -10 -49 -36 -44 -38 7 -64 -23 -64 -72 0 -23 7
-51 15 -64 14 -18 24 -21 68 -19 l52 3 0 105 c0 91 -2 104 -17 107 -12 2 -18
-3 -18 -16z m-10 -93 c6 -11 8 -34 5 -50 -5 -24 -12 -29 -28 -27 -35 5 -38 98
-3 98 9 0 20 -9 26 -21z"/>
					<path d="M6860 1519 c0 -100 2 -110 18 -107 13 2 18 16 22 63 4 52 8 60 25 60
18 0 20 -8 23 -62 3 -55 6 -63 22 -63 18 0 20 7 20 64 0 76 -17 101 -62 92
-26 -5 -28 -4 -28 29 0 28 -4 35 -20 35 -19 0 -20 -7 -20 -111z"/>
					<path d="M7429 1628 c-1 -2 -2 -16 -3 -33 -2 -27 -5 -30 -30 -27 -43 5 -66
-22 -66 -78 0 -61 19 -79 83 -77 l47 2 0 108 c0 88 -3 107 -15 107 -8 0 -16
-1 -16 -2z m-9 -118 c9 -32 -5 -70 -25 -70 -24 0 -38 40 -26 74 13 34 42 32
51 -4z"/>
					<path d="M3896 1601 c-30 -33 -17 -63 39 -92 34 -17 50 -32 50 -45 0 -27 -40
-30 -62 -5 -22 26 -43 27 -43 2 0 -47 100 -70 134 -32 10 11 16 31 14 48 -2
22 -13 33 -53 53 -27 14 -50 32 -50 40 0 21 53 26 61 6 7 -19 44 -21 44 -3 0
44 -101 65 -134 28z"/>
					<path d="M4065 1578 c-10 -21 -12 -34 -5 -36 5 -2 10 -24 10 -48 0 -58 14 -84
45 -84 28 0 32 14 10 36 -21 21 -19 80 3 93 16 10 16 12 0 25 -10 7 -18 21
-18 30 0 28 -28 18 -45 -16z"/>
					<path d="M5520 1594 c0 -9 -8 -23 -17 -30 -17 -13 -17 -15 0 -25 13 -7 17 -23
17 -63 0 -54 8 -66 46 -66 16 0 19 27 4 32 -16 5 -18 78 -3 93 15 15 17 35 4
35 -5 0 -11 9 -14 20 -6 24 -37 27 -37 4z"/>
					<path d="M6240 1591 c0 -11 -6 -21 -12 -24 -10 -3 -10 -9 0 -26 6 -11 12 -43
12 -71 0 -44 3 -50 24 -56 26 -6 46 1 46 17 0 5 -7 9 -15 9 -11 0 -15 12 -15
50 0 28 5 50 10 50 6 0 10 7 10 15 0 8 -4 15 -9 15 -5 0 -11 9 -14 20 -7 26
-37 26 -37 1z"/>
					<path d="M4178 1559 c-10 -5 -18 -17 -18 -24 0 -17 26 -20 35 -5 9 15 45 12
45 -3 0 -8 -18 -19 -39 -26 -21 -6 -42 -19 -45 -28 -10 -25 3 -52 27 -57 32
-7 102 -2 100 7 -2 4 -3 31 -3 61 0 36 -5 60 -16 70 -17 18 -60 20 -86 5z m62
-94 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30 26 11 17 46 13 46 -4z"/>
					<path d="M4300 1562 c0 -4 11 -41 25 -81 24 -73 24 -101 -1 -101 -8 0 -14 -7
-14 -15 0 -16 27 -20 47 -7 18 12 83 201 72 208 -15 9 -39 -3 -39 -20 0 -8 -5
-27 -11 -43 l-11 -28 -17 45 c-9 26 -24 46 -34 48 -9 2 -17 -1 -17 -6z"/>
					<path d="M4562 1554 c-18 -12 -22 -25 -22 -64 0 -55 22 -80 71 -80 23 0 59 26
59 43 0 11 -38 8 -45 -3 -20 -33 -56 10 -47 57 6 33 28 40 53 17 15 -14 22
-15 32 -5 9 10 7 16 -12 32 -28 23 -59 24 -89 3z"/>
					<path d="M4725 1558 c-29 -16 -35 -28 -35 -70 0 -47 28 -78 70 -78 44 0 70 31
70 84 0 35 -5 47 -25 60 -28 19 -52 20 -80 4z m49 -24 c30 -12 18 -94 -14 -94
-29 0 -42 64 -18 88 14 14 12 14 32 6z"/>
					<path d="M4863 1554 c-2 -6 -3 -40 -1 -75 2 -51 7 -64 20 -67 14 -3 17 7 20
60 3 57 5 63 26 66 12 2 23 -1 23 -7 1 -6 3 -15 4 -21 1 -5 3 -31 4 -56 1 -38
4 -45 19 -42 14 3 17 13 14 68 -2 36 -8 70 -13 75 -14 14 -110 13 -116 -1z"/>
					<path d="M5033 1554 c-2 -6 -3 -40 -1 -75 2 -51 7 -64 20 -67 14 -3 17 7 20
60 3 56 5 63 25 66 20 3 22 -1 25 -60 2 -50 6 -63 21 -66 15 -3 17 5 17 67 0
45 -4 73 -12 78 -19 12 -110 10 -115 -3z"/>
					<path d="M5220 1552 c-33 -27 -33 -97 -1 -123 28 -23 70 -25 97 -4 20 15 18
17 -36 19 -24 1 -35 6 -35 16 0 11 14 16 50 18 48 3 51 1 62 -27 14 -37 55
-51 92 -30 32 18 39 39 12 39 -12 0 -21 -5 -21 -11 0 -22 -44 -4 -50 21 -14
52 21 90 50 55 16 -19 40 -19 40 -1 0 19 -38 46 -65 46 -29 0 -65 -28 -65 -51
0 -10 -4 -19 -10 -19 -5 0 -10 9 -10 19 0 24 -36 51 -66 51 -12 0 -32 -8 -44
-18z m68 -35 c2 -11 -3 -17 -17 -17 -23 0 -35 15 -26 31 10 15 39 6 43 -14z"/>
					<path d="M5615 1545 c-62 -62 6 -161 89 -129 9 3 16 13 16 21 0 11 -7 13 -30
8 -32 -7 -65 9 -54 26 3 5 25 9 50 9 42 0 44 1 44 29 0 58 -72 80 -115 36z
m73 -28 c3 -12 -3 -17 -22 -17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z"/>
					<path d="M6086 1551 c-25 -28 -12 -55 34 -74 22 -8 40 -20 40 -26 0 -14 -47
-14 -55 -1 -8 13 -35 13 -35 1 0 -39 77 -54 109 -22 29 29 17 59 -30 71 -23 6
-39 17 -39 25 0 17 35 20 45 5 9 -15 35 -12 35 5 0 35 -76 47 -104 16z"/>
					<path d="M6338 1556 c-10 -7 -18 -18 -18 -25 0 -14 27 -15 35 -1 9 15 45 12
45 -3 0 -8 -18 -19 -39 -26 -21 -6 -42 -19 -45 -28 -10 -25 3 -52 27 -57 32
-7 102 -2 100 7 -2 4 -3 31 -3 61 0 36 -5 60 -16 70 -19 19 -63 20 -86 2z m62
-91 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30 26 11 17 46 13 46 -4z"/>
					<path d="M6468 1565 c-3 -4 5 -39 18 -78 29 -85 30 -97 5 -104 -24 -6 -16 -33
10 -33 10 0 25 13 33 29 24 45 69 181 63 188 -14 13 -37 -9 -48 -47 -14 -45
-23 -43 -36 10 -9 32 -30 49 -45 35z"/>
					<path d="M6721 1554 c-29 -21 -13 -41 19 -24 22 12 50 7 50 -8 0 -5 -14 -12
-32 -16 -41 -8 -58 -23 -58 -51 0 -33 19 -43 78 -42 l52 1 0 62 c0 54 -3 65
-22 78 -29 20 -58 20 -87 0z m69 -78 c0 -18 -15 -36 -31 -36 -12 0 -19 7 -19
20 0 15 7 20 25 20 14 0 25 -2 25 -4z"/>
					<path d="M7045 1545 c-62 -62 6 -161 89 -129 9 3 16 13 16 21 0 11 -7 13 -30
8 -32 -7 -65 9 -54 26 3 5 25 9 50 9 40 0 44 2 44 25 0 32 -33 65 -65 65 -14
0 -37 -11 -50 -25z m70 -25 c0 -8 -10 -16 -22 -18 -22 -3 -32 16 -16 32 12 11
38 2 38 -14z"/>
					<path d="M7203 1558 c-13 -6 -23 -17 -23 -25 0 -15 26 -18 35 -3 9 15 45 12
45 -4 0 -8 -15 -17 -35 -20 -47 -9 -67 -46 -40 -76 14 -15 29 -19 66 -17 27 1
49 5 49 10 0 4 0 31 0 61 0 78 -34 104 -97 74z m57 -92 c0 -8 -5 -18 -10 -21
-17 -11 -40 3 -34 20 8 19 44 20 44 1z"/>
					<path d="M5934 1433 c-3 -10 -10 -28 -14 -40 -6 -13 -6 -25 0 -28 11 -7 40 34
40 57 0 24 -19 33 -26 11z"/>
					<path d="M7505 1440 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z" />
					<path d="M7600 1430 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
					<path d="M7692 1433 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
-15 0 -20 -5 -18 -17z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
